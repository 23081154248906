<template>
    <div>
        <b-row class="p-1">
            <b-col cols="9">
                <b-row>
                    <b-col
                        cols="3"
                        v-for="(data, index) in generalForm"
                        :key="index"
                    >
                        <b-form-group :label="$t(data.label)">
                            <b-form-input
                                :placeholder="$t(data.label)"
                                v-model="data.value"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-col>

            <b-col cols="3">
                <b-form-group label="Member Ranking">
                    <b-form-rating
                        v-model="rate"
                        variant="yellow"
                        inline
                        no-border
                        class="p-0"
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <div>
            <div
                class="bg-secondaryColor w-25 text-uppercase rounded text-white p-1"
                v-text="$t(locale.billinglabel)"
            />

            <b-row class="p-1">
                <b-col cols="9">
                    <b-row>
                        <b-col
                            cols="4"
                            v-for="(data, index) in billingForm"
                            :key="index"
                        >
                            <b-form-group :label="$t(data.label)">
                                <b-form-input
                                    :placeholder="$t(data.label)"
                                    v-model="data.value"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="3">
                    <div class="d-flex align-items-end flex-column bigGap">
                        <p class="mb-0 h6">Individual Earnings</p>
                        <p class="mb-0 h4">
                            4680 / <span class="h6" v-text="$t(locale.year)" />
                        </p>
                    </div>
                </b-col>
            </b-row>
        </div>

        <div>
            <div
                class="bg-secondaryColor w-25 text-uppercase rounded text-white p-1"
                v-text="$t(locale.socialLabel)"
            />

            <b-row class="p-1">
                <b-col cols="9">
                    <mdicon
                        class="text-yellow btn-icon-position"
                        :width="50"
                        :height="50"
                        name="account"
                    />
                </b-col>
                <b-col cols="3">
                    <div class="d-flex align-items-end flex-column bigGap">
                        <p class="mb-0 h6">Social Circle Earnings</p>
                        <p class="mb-0 h4">
                            396 8080 /
                            <span class="h6" v-text="$t(locale.year)" />
                        </p>
                    </div>
                </b-col>
            </b-row>
        </div>

        <div>
            <div
                class="bg-secondaryColor w-25 text-uppercase rounded text-white p-1"
                v-text="$t(locale.memberShipLabel)"
            />

            <b-row class="p-1">
                <b-col cols="9">
                    <b-row>
                        <b-col
                            cols="4"
                            v-for="(data, index) in membershipProfile"
                            :key="index"
                        >
                            <b-form-group
                                class="text-capitalize"
                                :label="$t(data.label)"
                            >
                                <b-form-input
                                    class="text-capitalize"
                                    :placeholder="$t(data.label)"
                                    v-model="data.value"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="3">
                    <div class="d-flex align-items-end flex-column bigGap">
                        <b-form-checkbox
                            value="A"
                            class="custom-control-primary"
                        >
                            {{ $t(locale.activated) }}
                        </b-form-checkbox>

                        <mdicon
                            class="text-danger btn-icon-position"
                            :width="30"
                            :height="30"
                            name="delete"
                        />
                    </div>
                </b-col>
            </b-row>
        </div>

        <div class="w-100 text-center p-2">
            <b-button
                v-ripple.400
                variant="secondaryColor"
                v-text="$t(locale.save)"
            />
        </div>
    </div>
</template>

<script>
import {profileMixin} from './profileMixins';

export default {
    mixins: [profileMixin],
    name: 'golfrelatedmemberdetailmembershipManagement',
    data() {
        return {
            rate: 3,

            locale: {
                year: 'year',
                save: 'save',
                ranking: 'golfRelatedCard.detail.profileTab.memberRanking',
                activated: 'golfRelatedCard.detail.profileTab.activated',
                billinglabel: 'golfRelatedCard.detail.profileTab.billinglabel',
                socialLabel: 'golfRelatedCard.detail.profileTab.socialLabel',
                memberShipLabel:
                    'golfRelatedCard.detail.profileTab.memberShipLabel',
                individualEarnings:
                    'golfRelatedCard.detail.profileTab.individualEarnings',
                socialCircleEarnings:
                    'golfRelatedCard.detail.profileTab.socialCircleEarnings',
            },
        };
    },
};
</script>

<style></style>
