<template>
    <div class="" style="min-height: 55vh">
        <b-row class="p-1">
            <b-col cols="12">
                <b-table
                    show-empty
                    responsive
                    sticky-header
                    :fields="fields"
                    :items="items"
                    hover
                >
                    <template #head(title)="data">
                        <div class="d-flex justify-content-center">
                            <span
                                class="my-auto"
                                style="margin-right: 10px"
                                v-text="$t(data.field.label)"
                            />
                        </div>
                    </template>

                    <template #head(ticketNumber)="data">
                        <div class="d-flex ml-1 justify-content-center">
                            <span
                                class="my-auto"
                                style="margin-right: 10px"
                                v-text="$t(data.field.label)"
                            />
                        </div>
                    </template>

                    <template #head(creationDate)="data">
                        <div class="d-flex ml-1 justify-content-center">
                            <span
                                class="my-auto"
                                style="margin-right: 10px"
                                v-text="$t(data.field.label)"
                            />
                        </div>
                    </template>
                    <template #head(solutionDate)="data">
                        <div class="d-flex ml-1 justify-content-center">
                            <span
                                class="my-auto"
                                style="margin-right: 10px"
                                v-text="$t(data.field.label)"
                            />
                        </div>
                    </template>
                    <template #head(status)="data">
                        <div class="d-flex ml-1 justify-content-center">
                            <span
                                class="my-auto"
                                style="margin-right: 10px"
                                v-text="$t(data.field.label)"
                            />
                        </div>
                    </template>

                    <template #head(action)="data">
                        <div class="d-flex ml-1 justify-content-center">
                            <span
                                class="my-auto"
                                style="margin-right: 10px"
                                v-text="$t(data.field.label)"
                            />
                        </div>
                    </template>

                    <template #cell(title)="data">
                        <div class="d-flex justify-content-center">
                            <p
                                class="font-weight-bold text-capitalize mb-0"
                                v-text="data.value"
                            />
                        </div>
                    </template>
                    <template #cell(ticketNumber)="data">
                        <div class="d-flex justify-content-center">
                            <p
                                class="font-weight-bold text-capitalize mb-0"
                                v-text="data.value"
                            />
                        </div>
                    </template>
                    <template #cell(creationDate)="data">
                        <div class="d-flex justify-content-center">
                            <p
                                class="font-weight-bold text-capitalize mb-0"
                                v-text="data.value"
                            />
                        </div>
                    </template>
                    <template #cell(solutionDate)="data">
                        <div class="d-flex justify-content-center">
                            <p
                                class="font-weight-bold text-capitalize mb-0"
                                v-text="data.value"
                            />
                        </div>
                    </template>

                    <template #cell(status)="data">
                        <div class="d-flex justify-content-center">
                            <p
                                class="font-weight-bold text-capitalize mb-0"
                                v-text="data.value"
                            />
                        </div>
                    </template>
                    <template #cell(action)>
                        <div class="d-flex justify-content-center">
                            <p
                                class="font-weight-bold text-capitalize mb-0"
                                v-text="'action'"
                            />
                        </div>
                    </template>
                </b-table>
            </b-col>

            <b-col cols="12">
                <b-row align-h="between" align-v="center">
                    <b-col cols="6">
                        <div
                            class="d-flex justify-content-between justify-content-sm-start align-items-center w-100 order-1 order-sm-2"
                        >
                            <p
                                class="mb-0 mr-1 d-none d-sm-block h6"
                                v-text="$t(locale.showPerPage)"
                            />
                            <div class="">
                                <b-form-select
                                    v-model="pagination.perPage"
                                    id="perPageSelect"
                                    size="sm"
                                    inline
                                    :options="pageOptions"
                                />
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="6" class="d-flex justify-content-end">
                        <b-pagination
                            v-model="pagination.currentPage"
                            :total-rows="pagination.totalMembers"
                            :per-page="pagination.perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0 order-2 order-sm-1"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: 'golfrelatedmemberdetailsupportHistory',
    data() {
        return {
            items: [
                {
                    title: 'Tom',
                    ticketNumber: 2222,
                    creationDate: '2022-06-30',
                    solutionDate: '2022-06-30',
                    status: 'PAID',
                },
            ],
            fields: [
                {
                    key: 'title',
                    label: 'golfRelatedCard.table.title',
                    //tdClass: 'bTableThStyle',
                    thStyle: {
                        padding: '0.72rem 0',
                        width: '150px',
                    },
                },
                {
                    key: 'ticketNumber',
                    label: 'golfRelatedCard.table.ticketNumber',
                    //tdClass: 'bTableThStyle',
                    thStyle: {
                        padding: '0.72rem 0',
                        width: '150px',
                    },
                },
                {
                    key: 'creationDate',
                    label: 'golfRelatedCard.table.creationDate',
                    //tdClass: 'bTableThStyle',
                    thStyle: {
                        padding: '0.72rem 0',
                        width: '150px',
                    },
                },
                {
                    key: 'solutionDate',
                    label: 'golfRelatedCard.table.solutionDate',
                    //tdClass: 'bTableThStyle',
                    thStyle: {
                        padding: '0.72rem 0',
                        width: '150px',
                    },
                },
                {
                    key: 'status',
                    label: 'golfRelatedCard.table.status',
                    //tdClass: 'bTableThStyle',
                    thStyle: {
                        padding: '0.72rem 0',
                        width: '150px',
                    },
                },
                {
                    key: 'action',
                    label: 'golfRelatedCard.action',
                    //tdClass: 'bTableThStyle',
                    thStyle: {
                        padding: '0.72rem 0',
                        width: '150px',
                    },
                },
            ],
            pageOptions: [3, 5, 10, 20],
            pagination: {
                currentPage: 1,
                totalMembers: 0,
                perPage: 20,
            },
            locale: {
                year: 'year',
                save: 'save',
                showPerPage: 'golfRelatedCard.showPerPage',
            },
        };
    },
};
</script>

<style></style>
