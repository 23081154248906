<template>
    <div>
        <b-row class="p-1">
            <b-col cols="9">
                <b-row>
                    <b-col
                        cols="3"
                        v-for="(data, index) in generalForm"
                        :key="index"
                    >
                        <b-form-group :label="$t(data.label)">
                            <b-form-input
                                :placeholder="$t(data.label)"
                                v-model="data.value"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-col>

            <b-col cols="3">
                <b-form-group label="Member Ranking">
                    <b-form-rating
                        v-model="rate"
                        variant="yellow"
                        inline
                        no-border
                        class="p-0"
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <div>
            <div
                class="bg-secondaryColor w-25 text-uppercase rounded text-white p-1"
                v-text="$t(locale.attendance)"
            />

            <b-row class="p-1">
                <b-col cols="12">
                    <apexchart type="bar" :options="options" :series="series" />
                </b-col>
            </b-row>
        </div>

        <div>
            <div
                class="bg-secondaryColor w-25 text-uppercase rounded text-white p-1"
                v-text="$t(locale.golfScores)"
            />

            <b-row class="p-1">
                <b-col cols="12"> </b-col>
            </b-row>
        </div>

        <div class="w-100 text-center p-2">
            <b-button
                v-ripple.400
                variant="secondaryColor"
                v-text="$t(locale.save)"
            />
        </div>
    </div>
</template>

<script>
import {profileMixin} from './profileMixins';

export default {
    mixins: [profileMixin],
    name: 'golfrelatedmemberdetailsportDetail',
    data() {
        return {
            rate: 3,
            options: {
                chart: {
                    id: 'vuechart-example',
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                },
                xaxis: {
                    label: {
                        show: true,
                    },
                    title: {
                        text: this.$t('Message.month'),
                        style: {
                            fontSize: '20px',
                            fontWeight: 600,
                            cssClass: 'chartTitle',
                        },
                    },
                    categories: [
                        1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998,
                    ],
                },
                title: {
                    text: this.$t('dashboardCard.chartTitle'),
                    align: 'left',
                },
            },
            series: [
                {
                    name: 'Units solds',
                    data: [30, 40, 45, 50, 49, 60, 70, 91],
                },
            ],
            locale: {
                year: 'year',
                save: 'save',
                ranking: 'golfRelatedCard.detail.profileTab.memberRanking',
                activated: 'golfRelatedCard.detail.profileTab.activated',
                billinglabel: 'golfRelatedCard.detail.profileTab.billinglabel',
                attendance: 'golfRelatedCard.detail.sportDetailTab.attendance',
                golfScores: 'golfRelatedCard.detail.sportDetailTab.golfScores',
            },
        };
    },
};
</script>

<style></style>
