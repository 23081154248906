<template>
  <b-row style="min-height: 70vh">
    <b-col cols="2" style="min-height: 70vh">
      <b-card
          no-body
          img-src="https://images.unsplash.com/photo-1538648759472-7251f7cb2c2f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1674&q=80"
          img-alt="Profile Cover Photo"
          img-top
          class="card-profile h-100"
      >
        <div class="p-1 bg-theme-teal h-full">
          <div class="profile-image-wrapper">
            <div
                class="profile-image p-0 position-absolute"
                style="top: 85px; left: 51px"
            >
              <b-avatar
                  v-if="userData.image"
                  size="60"
                  variant="light"
                  :src="userData.image"
              />
              <b-avatar
                  v-else
                  size="60"
                  variant="light"
                  src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"
              />
            </div>
          </div>
          <div class="mt-1">
            <h5 class="text-white" v-text="$t(locale.memberSince)"/>
            <h6 class="text-white" v-if="userData.signup_date">
              {{ userData.signup_date.split("T")[0] }}
            </h6>
          </div>

          <div class="my-1">
            <!-- <b-badge class="profile-badge" variant="primary">
                            Pro Level
                        </b-badge> -->
          </div>

          <b-card-text class="text-left">
            <div class="d-flex flex-column bigGap">
              <div
                  class="d-flex align-items-center smallGap text-white"
                  v-for="(info, index) in userInfo"
                  :key="index"
              >
                <mdicon :width="20" :height="20" :name="info.icon"/>

                <p
                    class="mb-0 text-truncate text-white font-weight-normal"
                    style="font-size: 7px"
                    v-text="info.value"
                />
              </div>
            </div>
          </b-card-text>
        </div>
      </b-card>
    </b-col>
    <b-col cols="10" style="min-height: 50vh">
      <b-row>
        <b-col cols="12">
          <b-card no-body>
            <b-navbar
                class="navbar navbar-shadow justify-content-between align-items-center"
                style="min-height: 4.45rem"
            >
              <b-nav tabs class="mb-0 rounded">
                <b-nav-item
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :active="tab.active"
                    @click="switchNavbar(index)"
                >
                  {{ $t(tab.label) }}
                </b-nav-item>
              </b-nav>

              <div
                  role="button"
                  class="d-flex align-items-center justify-content-start justify-content-lg-end smallGap"
              >
                <!-- <mdicon
                  :width="16"
                  :height="16"
                  role="button"
                  name="plus-box-multiple"
                  class="text-yellow"
                />
                <p
                  class="mb-0 font-weight-bold"
                  style="font-size: 12px"
                  v-text="$t(locale.addNewUser)"
                />-->
              </div>
            </b-navbar>
          </b-card>
        </b-col>

        <b-col cols="12" class="">
          <b-card no-body class="h-100">
            <KeepAlive>
              <transition name="zoom-fade" mode="out-in">
                <component :user="userData" :is="currentComponent"/>
              </transition>
            </KeepAlive>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
    <Modal
        id="modal-access-denied"
        :hideFooter="true"
        :no-close-on-backdrop="true"
        :hide-header="true"
        :hide-back-drop="true"
    >
      <access-denied/>
    </Modal>
  </b-row>
</template>

<script>
import profile from "@/@core/components/golfrelated/memberdetail/profile.vue";
import billing from "@/@core/components/golfrelated/memberdetail/billing.vue";
import supportHistory from "@/@core/components/golfrelated/memberdetail/supportHistory.vue";
import sportDetail from "@/@core/components/golfrelated/memberdetail/sportDetail.vue";
import membershipManagement from "@/@core/components/golfrelated/memberdetail/membershipManagement.vue";
import notes from "@/@core/components/golfrelated/memberdetail/notes.vue";
import accessDenied from "@core/components/permission/accessDenied";

export default {
  name: "MembersDetail",
  components: {
    profile,
    billing,
    supportHistory,
    sportDetail,
    membershipManagement,
    notes,
    accessDenied
  },
  data() {
    return {
      currentComponent: "profile",
      tabs: [
        {
          label: "golfRelatedCard.detail.profile",
          components: "profile",
          active: true,
        },
        {
          label: "golfRelatedCard.detail.billing",
          components: "billing",
          active: false,
        },
        {
          label: "golfRelatedCard.detail.membership",
          components: "membershipManagement",
          active: false,
        },
        {
          label: "golfRelatedCard.detail.notes",
          components: "notes",
          active: false,
        },
        {
          label: "golfRelatedCard.detail.sportDetail",
          components: "sportDetail",
          active: false,
        },
        {
          label: "golfRelatedCard.detail.support",
          components: "supportHistory",
          active: false,
        },
      ],
      tabsDropdown: [
        {
          label: "golfRelatedCard.detail.sportDetail",
          active: false,
        },
        {
          label: "golfRelatedCard.detail.support",
          active: false,
        },
      ],
      userData: {},
      userInfo: [
        {
          icon: "email-outline",
          value: "hans.david.karlsson@gmail.com",
          fieldValue: "email",
        },
        {
          icon: "phone",
          value: "0795067980",
          fieldValue: "mobile_phone_number",
        },
        {
          icon: "identifier",
          value: "123456-789",
          fieldValue: "accounting_customer",
        },
        {
          icon: "clock-time-one-outline",
          value: "123456-789",
          fieldValue: "",
        },
      ],
      locale: {
        memberSince: "golfRelatedCard.table.memberSince",
        addNewUser: "golfRelatedCard.addNewUser",
      },
    };
  },
  mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)

    const {uuid} = this.$route.params;
    if (uuid) {
      this.getMemberInformation(uuid);
    }
  },
  methods: {
    getUserSubscriptions() {
      this.$useJwt.subscription().catch(err => {
        if (err.response.status === 403) {
          window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
        }
      })
    },
    switchNavbar(index) {
      this.tabs.find((item) => item.active == true).active = false;

      this.currentComponent = this.tabs[index].components;

      this.tabs[index].active = true;
    },

    switchNavbarDropdown(index) {
      let tabsLastIndex = this.tabs.length - 1;
      this.tabsDropdown[index].active = true;
      this.tabsDropdown.push(this.tabs[tabsLastIndex]);

      this.tabs.find((item) => item.active == true).active = false;
      this.tabs.splice(tabsLastIndex, 1, this.tabsDropdown[index]);
      this.tabsDropdown.splice(index, 1);
    },
    getMemberInformation(uuid) {
      this.$useJwt
          .getMembers({URL: `/${uuid}`, method: "get"})
          .then((res) => {
            this.userData = res.data.data;
            this.userInfo.map((el) => {
              if (Object.keys(res.data.data).includes(el.fieldValue)) {
                el.value = res.data.data[el.fieldValue];
              }
            });
          })
          .catch((err) => {
            this.loading = false;
            if (err.response.status === 403) {
              this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', true)
              this.$bvModal.show("modal-access-denied");
            }
          });
    },
  },
};
</script>

<style></style>
