import { getCountries } from '@/views/newViews/accountingData/constants/countries';

export const profileMixin = {
    data() {
        return {
            generalForm: [
                {
                    disabled:true,
                    label: 'golfRelatedCard.detail.profileTab.firstName',
                    value: '',
                    rules: '',
                    fieldValue : 'first_name'
                },
                {
                    disabled:true,
                    label: 'golfRelatedCard.detail.profileTab.lastName',
                    value: '',
                    rules: '',
                    fieldValue : 'last_name'
                },
                // {
                //     label: 'golfRelatedCard.detail.profileTab.spouse',
                //     value: '',
                //     rules: '',
                //     fieldValue : ''
                // },
                // {
                //     label: 'golfRelatedCard.detail.profileTab.children',
                //     value: '',
                //     rules: '',
                //     fieldValue : ''
                // },
                {
                    disabled:true,
                    label: 'golfRelatedCard.detail.profileTab.age',
                    value: '',
                    rules: '',
                    fieldValue : 'age'
                },
                {
                    disabled:true,
                    label: 'golfRelatedCard.detail.profileTab.country',
                    type : 'select',
                    options : [...getCountries()],
                    value: '',
                    rules: '',
                    fieldValue : 'country'
                },
                // {
                //     label: 'golfRelatedCard.detail.profileTab.memberId',
                //     value: '',
                //     rules: '',
                //     fieldValue : ''
                // },
                {
                    disabled:true,
                    label: 'golfRelatedCard.detail.profileTab.phoneNumber',
                    value: '',
                    rules: '',
                    fieldValue : 'mobile_phone_number'
                },
                {
                    disabled:true,
                    label: 'golfRelatedCard.detail.profileTab.email',
                    value: '',
                    rules: '',
                    fieldValue : 'email'
                },
                {
                    disabled:true,
                    label: 'golfRelatedCard.detail.profileTab.address',
                    value: '',
                    rules: '',
                    fieldValue : 'home_street_address'
                },
                {
                    disabled:true,
                    label: 'golfRelatedCard.detail.profileTab.zipCode',
                    value: '',
                    rules: '',
                    fieldValue : 'zip_code'
                },
                {
                    disabled:true,
                    label: 'golfRelatedCard.detail.profileTab.city',
                    value: '',
                    rules: '',
                    fieldValue : 'city'
                },
            ],
            billingForm: [
                {
                    label: 'golfRelatedCard.detail.profileTab.billingName',
                    value: '',
                    rules: '',
                    fieldValue : 'billing_name'
                },
                {
                    label: 'golfRelatedCard.detail.profileTab.billingEmail',
                    value: '',
                    rules: '',
                    fieldValue : 'billing_email'
                },
                {
                    label: 'golfRelatedCard.detail.profileTab.billingCycle',
                    value: '',
                    rules: '',
                    fieldValue : 'billing_cycle'
                },
                {
                    label: 'golfRelatedCard.detail.profileTab.billingRef',
                    value: '',
                    rules: '',
                    fieldValue : 'billing_reference'
                },
                {
                    label: 'golfRelatedCard.detail.profileTab.billingMethod',
                    value: '',
                    rules: '',
                    fieldValue : 'billing_method'
                },
                {
                    label: 'golfRelatedCard.detail.profileTab.memberId',
                    value: '',
                    rules: '',
                    fieldValue : 'member_id'
                },
                {
                    label: 'golfRelatedCard.detail.profileTab.economyContactEmail',
                    value: '',
                    rules: '',
                    fieldValue : 'economy_contact_email'
                },

                {
                    label: 'golfRelatedCard.detail.profileTab.email',
                    value: '',
                    rules: '',
                     fieldValue : 'email'
                },
                {
                    label: 'golfRelatedCard.detail.profileTab.receiptsEmail',
                    value: '',
                    rules: '',
                     fieldValue : 'receipts_email'
                },
            ],
            social: [],
            membershipProfile: [
                {
                    label: 'golfRelatedCard.table.memberCategory',
                    value: '',
                    rules: '',
                },
                {
                    label: 'golfRelatedCard.detail.profileTab.fee',
                    value: '',
                    rules: '',
                },

                {
                    label: 'golfRelatedCard.detail.profileTab.memberId',
                    value: '',
                    rules: '',
                },
                {
                    label: 'golfRelatedCard.detail.profileTab.discount',
                    value: '',
                    rules: '',
                },

                {
                    label: 'golfRelatedCard.detail.profileTab.bonus',
                    value: '',
                    rules: '',
                },
                {
                    label: 'golfRelatedCard.detail.profileTab.benefits',
                    value: '',
                    rules: '',
                },
            ],
        };
    },
};
