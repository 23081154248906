<template>
    <div style="height: 55vh">
        <b-card no-body>
            <b-card-body>
                <div class="w-100 scrol-area">
                    <b-row align-h="start">
                        <b-col cols="6" class="note-right d-flex p-3">
                            <div class="bg-secondaryColor arrow-right" />
                            <b-card bg-variant="greenLight" class="mb-0">
                                <b-card-text>
                                    Lorem ipsum dolor sit amet consectetur
                                    adipisicing elit. Repudiandae reprehenderit
                                    ea, odit odio assumenda vitae, velit

                                    <p
                                        class="mb-0 text-right font-weight-bold mt-1 text-secondaryColor"
                                        style="font-size: 12px"
                                    >
                                        Added by Darya Henric 2022-04-03 14.30
                                    </p>
                                </b-card-text>
                            </b-card>
                        </b-col>
                    </b-row>
                    <b-row align-h="end">
                        <b-col cols="6" class="note-left p-3">
                            <div class="bg-secondaryColor arrow-left" />
                            <b-card bg-variant="greenLight" class="mb-0">
                                <b-card-text>
                                    Lorem ipsum dolor sit amet consectetur
                                    adipisicing elit. Repudiandae reprehenderit
                                    ea, odit odio assumenda vitae, velit

                                    <p
                                        class="mb-0 text-right font-weight-bold mt-1 text-secondaryColor"
                                        style="font-size: 12px"
                                    >
                                        Added by Darya Henric 2022-04-03 14.30
                                    </p>
                                </b-card-text>
                            </b-card>
                        </b-col>
                    </b-row>

                    <b-row align-h="start">
                        <b-col cols="6" class="note-right d-flex p-3">
                            <div class="bg-secondaryColor arrow-right" />
                            <b-card bg-variant="greenLight" class="mb-0">
                                <b-card-text>
                                    Lorem ipsum dolor sit amet consectetur
                                    adipisicing elit. Repudiandae reprehenderit
                                    ea, odit odio assumenda vitae, velit

                                    <p
                                        class="mb-0 text-right font-weight-bold mt-1 text-secondaryColor"
                                        style="font-size: 12px"
                                    >
                                        Added by Darya Henric 2022-04-03 14.30
                                    </p>
                                </b-card-text>
                            </b-card>
                        </b-col>
                    </b-row>
                </div>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>

export default {
    name: 'golfrelatedmemberdetailnotes',

    data() {
        return {
            rate: 3,

            locale: {
                year: 'year',
                save: 'save',
                ranking: 'golfRelatedCard.detail.profileTab.memberRanking',
                activated: 'golfRelatedCard.detail.profileTab.activated',
                billinglabel: 'golfRelatedCard.detail.profileTab.billinglabel',
                socialLabel: 'golfRelatedCard.detail.profileTab.socialLabel',
                memberShipLabel:
                    'golfRelatedCard.detail.profileTab.memberShipLabel',
                individualEarnings:
                    'golfRelatedCard.detail.profileTab.individualEarnings',
                socialCircleEarnings:
                    'golfRelatedCard.detail.profileTab.socialCircleEarnings',
            },
        };
    },
};
</script>

<style scoped lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables.scss';

.scrol-area {
    height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: $primary transparent;
}

.note-right {
    border-right: 2px solid #16149f;
}

.note-right .arrow-right {
    position: absolute;
    right: 0px;
    top: 95px;
    height: 2px;
    width: 41px;
}
.note-left {
    border-left: 2px solid #16149f;
    position: relative;
    left: -2px;
}
.note-left .arrow-left {
    position: absolute;
    left: 0px;
    top: 95px;
    height: 2px;
    width: 41px;
}
</style>
