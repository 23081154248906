xxxxx
<template>
  <div class="" style="min-height: 55vh">
    <b-row class="p-1">
      <b-col cols="12">
        <b-table show-empty responsive :fields="fields" :items="items" hover>
          <template #head(invoiceNumber)="data">
            <div class="d-flex justify-content-center">
              <span
                class="my-auto"
                style="margin-right: 10px"
                v-text="$t(data.field.label)"
              />
            </div>
          </template>

          <template #head(invoiceNumberDate)="data">
            <div class="d-flex ml-1 justify-content-center">
              <span
                class="my-auto"
                style="margin-right: 10px"
                v-text="$t(data.field.label)"
              />
            </div>
          </template>

          <template #head(dueDate)="data">
            <div class="d-flex ml-1 justify-content-center">
              <span
                class="my-auto"
                style="margin-right: 10px"
                v-text="$t(data.field.label)"
              />
            </div>
          </template>
          <template #head(invoiceAmount)="data">
            <div class="d-flex ml-1 justify-content-center">
              <span
                class="my-auto"
                style="margin-right: 10px"
                v-text="$t(data.field.label)"
              />
            </div>
          </template>
          <template #head(invoiceBalance)="data">
            <div class="d-flex ml-1 justify-content-center">
              <span
                class="my-auto"
                style="margin-right: 10px"
                v-text="$t(data.field.label)"
              />
            </div>
          </template>
          <template #head(status)="data">
            <div class="d-flex ml-1 justify-content-center">
              <span
                class="my-auto"
                style="margin-right: 10px"
                v-text="$t(data.field.label)"
              />
            </div>
          </template>

          <template #head(action)="data">
            <div class="d-flex ml-1 justify-content-center">
              <span
                class="my-auto"
                style="margin-right: 10px"
                v-text="$t(data.field.label)"
              />
            </div>
          </template>

          <template #cell(invoiceNumber)="data">
            <div class="d-flex justify-content-center">
              <p
                class="font-weight-bold text-capitalize mb-0"
                v-text="data.item.document_number"
              />
            </div>
          </template>
          <template #cell(invoiceNumberDate)="data">
            <div class="d-flex justify-content-center">
              <p
                class="font-weight-bold text-capitalize mb-0"
                v-text="data.item.invoice_date"
              />
            </div>
          </template>
          <template #cell(dueDate)="data">
            <div class="d-flex justify-content-center">
              <p
                class="font-weight-bold text-capitalize mb-0"
                v-text="data.item.due_date"
              />
            </div>
          </template>
          <template #cell(invoiceAmount)="data">
            <div class="d-flex justify-content-center">
              <p
                class="font-weight-bold text-capitalize mb-0"
                v-text="data.item.total"
              />
            </div>
          </template>
          <template #cell(invoiceBalance)="data">
            <div class="d-flex justify-content-center">
              <p
                class="font-weight-bold text-capitalize mb-0"
                v-text="data.item.balance"
              />
            </div>
          </template>
          <template #cell(status)="data">
            <div class="d-flex justify-content-center">
              <b-button
                v-if="data.item.fully_paid"
                size="sm"
                variant="success"
                v-text="'PAID'"
              />
              <b-button
                v-else-if="data.item.cancelled"
                size="sm"
                variant="danger"
                v-text="'UNPAID'"
              />
            </div>
          </template>
          <template #cell(action)>
            <div class="d-flex justify-content-center">
              <span class="flex gap-1">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                    />
                  </svg>
                </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                    <path
                      fill-rule="evenodd"
                      d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                    />
                  </svg>
                </span>
              </span>
            </div>
          </template>
        </b-table>
      </b-col>

      <b-col cols="12">
        <b-row align-h="between" align-v="center">
          <b-col cols="6">
            <div
              class="d-flex justify-content-between justify-content-sm-start align-items-center w-100 order-1 order-sm-2"
            >
              <p
                class="mb-0 mr-1 d-none d-sm-block h6"
                v-text="$t(locale.showPerPage)"
              />
              <div class="">
                <b-form-select
                  v-model="pagination.perPage"
                  id="perPageSelect"
                  size="sm"
                  inline
                  :options="pageOptions"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="6" class="d-flex justify-content-end">
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.totalMembers"
              :per-page="pagination.perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 order-2 order-sm-1"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "golfrelatedmemberdetailbilling",
  data() {
    return {
      items: [],
      //     {
      //         document_number  : '4521',
      //         customer_name  : 'kelvin clin',
      //         total  : '1254',
      //         balance  : '100',
      //         fully_paid  : true,
      //         invoice_date  : '2022-10-12',
      //         due_date  : '2022-11-01',
      //         cancelled  : false,
      //     },
      // ],
      fields: [
        {
          key: "invoiceNumber",
          label: "dashboardCard.table.invoiceHead",
          //tdClass: 'bTableThStyle',
          thStyle: {
            padding: "0.72rem 0",
            width: "150px",
          },
        },
        {
          key: "invoiceNumberDate",
          label: "dashboardCard.table.invoiceDateHead",
          //tdClass: 'bTableThStyle',
          thStyle: {
            padding: "0.72rem 0",
            width: "150px",
          },
        },
        {
          key: "dueDate",
          label: "dashboardCard.table.dueDateHead",
          //tdClass: 'bTableThStyle',
          thStyle: {
            padding: "0.72rem 0",
            width: "150px",
          },
        },
        {
          key: "invoiceAmount",
          label: "golfRelatedCard.table.invoiceAmount",
          //tdClass: 'bTableThStyle',
          thStyle: {
            padding: "0.72rem 0",
            width: "150px",
          },
        },
        {
          key: "invoiceBalance",
          label: "golfRelatedCard.table.invoiceBalance",
          //tdClass: 'bTableThStyle',
          thStyle: {
            padding: "0.72rem 0",
            width: "150px",
          },
        },
        {
          key: "status",
          label: "golfRelatedCard.table.status",
          //tdClass: 'bTableThStyle',
          thStyle: {
            padding: "0.72rem 0",
            width: "150px",
          },
        },
        {
          key: "action",
          label: "golfRelatedCard.action",
          //tdClass: 'bTableThStyle',
          thStyle: {
            padding: "0.72rem 0",
            width: "150px",
          },
        },
      ],
      pageOptions: [3, 5, 10, 20],
      pagination: {
        currentPage: 1,
        totalMembers: 0,
        perPage: 20,
      },
      locale: {
        year: "year",
        save: "save",
        showPerPage: "golfRelatedCard.showPerPage",
      },
    };
  },
  mounted() {
    const { uuid } = this.$route.params;
    if (uuid) {
      this.getMemberBilling(uuid);
    }
  },
  methods: {
    getMemberBilling(uuid) {
      this.$useJwt
        .getMemberBilling({ URL: `/${uuid}`, method: "get" })
        .then((res) => {})
        .catch((err) => {});
    },
  },
};
</script>

<style></style>
