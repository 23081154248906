<template>
  <div>
    <b-row class="p-1">
      <b-col cols="9">
        <b-row>
          <b-col cols="4" v-for="(data, index) in generalForm" :key="index">
            <b-form-group :label="$t(data.label)" v-if="data.disabled">
              <div v-if="data.type === 'select'">
                <v-select
                    :placeholder="$t(locale.label)"
                    :options="data.options"
                    v-model="data.value"
                    @option:selected="computeSelect($event, data.fieldValue)"
                    class="resource-selector d-inline-block w-full"
                />
              </div>
              <div v-else>
                <b-form-input
                    :placeholder="$t(data.label)"
                    v-model="data.value"
                />
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="3">
        <b-form-group label="Member Ranking">
          <b-form-rating
              v-model="rate"
              variant="yellow"
              inline
              no-border
              class="p-0"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <div>
      <div
          class="bg-secondaryColor w-25 text-uppercase rounded text-white p-1"
          v-text="$t(locale.billinglabel)"
      />

      <b-row class="p-1">
        <b-col cols="9">
          <b-row>
            <b-col cols="4" v-for="(data, index) in billingForm" :key="index">
              <b-form-group :label="$t(data.label)">
                <b-form-input
                    :placeholder="$t(data.label)"
                    v-model="data.value"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <div class="d-flex align-items-end flex-column bigGap">
            <p class="mb-0 h6">Individual Earnings</p>
            <p class="mb-0 h4">
              4680 / <span class="h6" v-text="$t(locale.year)"/>
            </p>
          </div>
        </b-col>
      </b-row>
    </div>

    <div>
      <div
          class="bg-secondaryColor w-25 text-uppercase rounded text-white p-1"
          v-text="$t(locale.socialLabel)"
      />

      <b-row class="p-1">
        <b-col cols="9">
          <div class="flex justify-center">
            <VueFamilyTree :tree="tree" :enable-drag="true" style="width: 685px; height: 308px;overflow-x: scroll;">

              <template v-slot:card="{item}">
                <div class="custom-card">
                  <div v-if="item.hasOwnProperty('role') === false" class="w-full text-center">
                    <div class="flex justify-content-center">
                      <img :src="item.image" class="w-9 h-9 mr-2" :alt="item.name"/>
                    </div>
                    <span>{{ item.name }}</span>
                  </div>
                  <div v-else>
                    <div class="w-full">
                      <span>{{ item.name }}</span>
                    </div>
                    <div class="w-full">
                      <span>{{ item.role }}</span>
                    </div>
                  </div>
                </div>
              </template>

            </VueFamilyTree>
            <!-- @card-click="cardClick" -->
          </div>
        </b-col>
        <b-col cols="3">
          <div
              class="d-flex align-items-end justify-between flex-column bigGap"
          >
            <div class="flex flex-col mb-2" v-if="singleMember.family">
              <span
                  v-if="!singleMember.family.uuid"
                  class="text-theme-sidebar cursor-pointer"
                  @click="openCreateModal"
              >{{ $t(locale.createFamily) }}</span
              >
              <span v-else class="flex flex-col">
                <span
                    class="text-theme-sidebar cursor-pointer"
                    @click="updateFamily"
                >{{ $t(locale.editFamily) }} ({{
                    $t(locale.addMemberFamily)
                  }})</span
                >
                <!-- <span class="text-theme-sidebar cursor-pointer">{{ $t(locale.addMemberFamily) }}</span> -->
              </span>
            </div>
            <div class="flex flex-col">
              <p class="mb-0 h6">Social Circle Earnings</p>
              <p class="mb-0 h4" v-if="singleMember.hasOwnProperty('family')">
                <span v-if="singleMember.family">{{ singleMember.family.billed_this_year }}</span> /
                <span class="h6" v-text="$t(locale.year)"/>
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <div>
      <div
          class="bg-secondaryColor w-25 text-uppercase rounded text-white p-1"
          v-text="$t(locale.memberShipLabel)"
      />

      <b-row class="p-1">
        <b-col cols="9">
          <b-row>
            <b-col
                cols="4"
                v-for="(data, index) in membershipProfile"
                :key="index"
            >
              <b-form-group class="text-capitalize" :label="$t(data.label)">
                <b-form-input
                    class="text-capitalize"
                    :placeholder="$t(data.label)"
                    v-model="data.value"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <div class="d-flex align-items-end flex-column bigGap">
            <b-form-checkbox value="A" class="custom-control-primary">
              {{ $t(locale.activated) }}
            </b-form-checkbox>

            <mdicon
                class="text-danger btn-icon-position"
                :width="30"
                :height="30"
                name="delete"
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <div class="w-100 text-center p-2">
      <b-button
          :disabled="processing"
          variant="secondaryColor"
          @click="updateMemberInfo"
          v-text="processing ? $t('Message.loading') : $t(locale.save)"
      />
    </div>

    <CustomNoHeaderModalVue
        v-if="openFamilyCreateModal"
        width="w-11/12 md:w-1/3"
        :title="''"
        @handleClose="openFamilyCreateModal = false"
        :close="!true"
    >
      <div class="flex flex-col p-1" slot="header">
        <h2
            class="text-xl md:text-2xl font-semibold font-poppins text-gray-900"
        >
          {{
            editFamily
                ? $t(locale.updateFamilyForm)
                : $t(locale.createFamilyForm)
          }}
        </h2>
      </div>
      <div class="relative flex flex-col p-1 z-50" slot="body">
        <ValidationObserver ref="form">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
            <div>
              <ValidationProvider
                  #default="{ errors }"
                  rules="required"
                  :name="$t(locale.familyName)"
              >
                <b-form-group :label="$t(locale.familyName)">
                  <b-form-input
                      v-model="createFamilyForm.family_name"
                      :placeholder="$t(locale.familyName)"
                  />
                  <small class="text-danger" v-text="errors[0]"/>
                </b-form-group>
              </ValidationProvider>
            </div>
            <div>
              <b-form-group :label="$t(locale.memberRanking)">
                <b-form-rating
                    v-model="createFamilyForm.rating"
                    variant="yellow"
                    inline
                    no-border
                    class="p-0"
                />
              </b-form-group>
            </div>
            <div
                class="col-span-2 grid grid-cols-1 md:grid-cols-2 items-center gap-3"
            >
              <div>
                <ValidationProvider
                    #default="{ errors }"
                    :name="$t(locale.feeRule)"
                >
                  <b-form-group :label="$t(locale.feeRule)">
                    <v-select
                        class="w-full"
                        label="name"
                        v-model="feeRuleHolder"
                        @option:selected="computeCreateSelect($event, 'fee_rule')"
                        :filterable="false"
                        :options="feeRuleResult"
                        @search="searchFees"
                    >
                      <template slot="no-options"> Sök...</template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.name }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.name }}
                        </div>
                      </template>
                    </v-select>
                    <small class="text-danger" v-text="errors[0]"/>
                  </b-form-group>
                </ValidationProvider>
              </div>
              <div>
                <button
                    @click="openFeeRule = true"
                    class="flex justify-center c-p-1 focus:outline-none bg-theme-sidebar text-white rounded-full shadow-md"
                >
                  <small>{{ $t(locale.createFeeRule) }}</small>
                </button>
              </div>
            </div>
            <div class="flex flex-col col-span-2 mb-2">
              <span>{{ $t(locale.members) }}</span>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                <span
                    v-for="(item, i) in selectedMembers"
                    :key="item.uuid"
                    class="relative bg-theme-sidebar p-1 rounded-md text-white"
                >
                  <span class="absolute right-0">
                    <svg
                        @click="removeMember(i)"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 text-white cursor-pointer"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                      <path
                          fill-rule="evenodd"
                          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                          clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                  {{ $t(locale.role) }}: {{ item.role }} <br/>
                  <span v-if="item.uuid">
                    name: {{ item.member }}
                  </span>
                  <span v-else>
                    name :
                    {{ item.member.first_name + " " + item.member.last_name }}
                  </span>
                </span>
              </div>
            </div>
            <div
                class="col-span-2 grid grid-cols-1 md:grid-cols-2 gap-2 custom-border rounded-md c-p-2"
            >
              <div>
                <b-form-group :label="$t(locale.role)">
                  <b-form-input
                      v-model="miniFormRole.role"
                      :placeholder="$t(locale.role)"
                  />
                </b-form-group>
              </div>
              <div>
                <b-form-group label="Members">
                  <v-select
                      class="w-full"
                      v-model="miniFormRole.member"
                      label="first_name"
                      :filterable="false"
                      :options="members"

                      @search="searchMembers"
                  >
                    <template slot="no-options"> Sök...</template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.first_name + " " + option.last_name }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.first_name }}
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </div>
              <div>
                <button
                    @click="addMiniForm"
                    class="flex justify-center c-p-1 focus:outline-none bg-theme-sidebar text-white rounded-full shadow-md"
                >
                  <small>{{ $t(locale.addMember) }}</small>
                </button>
              </div>
            </div>
            <div class="col-span-2 flex justify-end w-full mt-1">
              <button
                  :disabled="creating"
                  @click="createFamily"
                  class="flex justify-center c-p-3 focus:outline-none bg-theme-teal text-white rounded-md shadow-md"
              >
                <span v-if="creating">{{ $t("Message.loading") }}</span>
                <span v-else>{{
                    editFamily
                        ? $t(locale.updateFamilyForm)
                        : $t(locale.createFamilyForm)
                  }}</span>
              </button>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </CustomNoHeaderModalVue>

    <CustomNoHeaderModalVue
        v-if="openFeeRule"
        width="w-11/12 md:w-1/4"
        :title="''"
        @handleClose="openFeeRule = false"
        :close="!true"
    >
      <div class="flex flex-col p-1" slot="header">
        <h2
            class="text-xl md:text-2xl font-semibold font-poppins text-gray-900"
        >
          {{ $t(locale.createFeeRule) }}
        </h2>
      </div>
      <div class="relative flex flex-col p-1 z-50" slot="body">
        <ValidationObserver ref="formCreateFeeRule">
          <b-row>
            <div class="flex flex-col w-full">
              <div class="w-full">
                <ValidationProvider
                    #default="{ errors }"
                    rules="required"
                    :name="$t(locale.feeRuleName)"
                >
                  <b-form-group :label="$t(locale.feeRuleName)" class="w-full">
                    <b-form-input
                        v-model="createFeeRule.name"
                        :placeholder="$t(locale.feeRuleName)"
                    />
                    <small class="text-danger" v-text="errors[0]"/>
                  </b-form-group>
                </ValidationProvider>
              </div>
              <div>
                <b-form-group :label="$t(locale.discount)">
                  <b-form-input
                      type="number"
                      min="0"
                      v-model="createFeeRule.discount"
                      :placeholder="$t(locale.discount)"
                  />
                </b-form-group>
              </div>
              <div>
                <b-form-group :label="$t(locale.discountType)">
                  <LittleTabs
                      :tabs="[
                      {
                        label: 'golfRelatedCard.detail.familyInfo.percentage',
                        active: true,
                        value: 'percentage',
                      },
                      {
                        label: 'golfRelatedCard.detail.familyInfo.amount',
                        active: false,
                        value: 'amount',
                      },
                    ]"
                      buttonSize="sm"
                      containerStyle="justify-content-start"
                      btnClass="tabWrapperSmall2"
                      v-model="createFeeRule.discount_type"
                      :defaultValue="true"
                  />
                </b-form-group>
              </div>
              <div class="col-span-2 flex justify-end w-full mt-1">
                <button
                    :disabled="addFeeRule"
                    @click="createFormFeeRule"
                    class="flex justify-center c-p-3 focus:outline-none bg-theme-teal text-white rounded-md shadow-md"
                >
                  <span v-if="addFeeRule">{{ $t("Message.loading") }}</span>
                  <span v-else>{{ $t(locale.createFeeRule) }}</span>
                </button>
              </div>
            </div>
          </b-row>
        </ValidationObserver>
      </div>
    </CustomNoHeaderModalVue>
  </div>
</template>

<script>
import {profileMixin} from "./profileMixins";
import vSelect from "vue-select";
import CustomNoHeaderModalVue from "@/@core/customComponent/CustomNoHeaderModal.vue";
import debounce from "lodash.debounce";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import useAppConfig from "@core/app-config/useAppConfig";
import VueFamilyTree from "vue-family-tree";
import {mapGetters} from "vuex"
import PAGES from "@/services/helpers/constant";

export default {
  mixins: [profileMixin],
  name: "golfrelatedmemberdetailprofile",
  components: {
    vSelect,
    CustomNoHeaderModalVue,
    ValidationProvider,
    ValidationObserver,
    VueFamilyTree,
  },
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      config: useAppConfig(),
      rate: 3,
      creating: false,
      hidden_fields: {...JSON.parse(JSON.stringify(PAGES['golf_related']['members']))},
      singleMember: {},
      processing: false,
      editFamily: false,
      openFamilyCreateModal: false,
      openFeeRule: false,
      addFeeRule: false,
      feeRuleResult: [],
      members: [],
      selectedMembers: [],
      createFamilyForm: {
        members: [],
        fee_rule: "",
        family_name: "",
        rating: 0,
      },
      createFeeRule: {
        name: "",
        discount: 0,
        discount_type: "percentage",
      },
      miniFormRole: {
        role: "",
        member: "",
      },
      feeRuleHolder: "",
      locale: {
        year: "year",
        save: "save",
        ranking: "golfRelatedCard.detail.profileTab.memberRanking",
        activated: "golfRelatedCard.detail.profileTab.activated",
        billinglabel: "golfRelatedCard.detail.profileTab.billinglabel",
        socialLabel: "golfRelatedCard.detail.profileTab.socialLabel",
        familyLabel: "golfRelatedCard.detail.profileTab.familyLabel",
        createFamily: "golfRelatedCard.detail.profileTab.createFamily",
        editFamily: "golfRelatedCard.detail.profileTab.editFamily",
        addMemberFamily: "golfRelatedCard.detail.profileTab.addMemberFamily",
        familyName: "golfRelatedCard.detail.familyInfo.familyName",
        memberRanking: "golfRelatedCard.detail.profileTab.memberRanking",
        feeRule: "golfRelatedCard.detail.familyInfo.feeRule",
        members: "golfRelatedCard.detail.familyInfo.members",
        createFeeRule: "golfRelatedCard.detail.familyInfo.createFeeRule",
        role: "golfRelatedCard.detail.familyInfo.role",
        addMember: "golfRelatedCard.detail.familyInfo.addMember",
        createFamilyForm: "golfRelatedCard.detail.familyInfo.createFamily",
        updateFamilyForm: "golfRelatedCard.detail.familyInfo.updateFamily",
        feeRuleName: "golfRelatedCard.detail.familyInfo.feeRuleName",
        discount: "golfRelatedCard.detail.familyInfo.discount",
        discountType: "golfRelatedCard.detail.familyInfo.discountType",
        percentage: "golfRelatedCard.detail.familyInfo.percentage",
        amount: "golfRelatedCard.detail.familyInfo.amount",

        memberShipLabel: "golfRelatedCard.detail.profileTab.memberShipLabel",
        individualEarnings:
            "golfRelatedCard.detail.profileTab.individualEarnings",
        socialCircleEarnings:
            "golfRelatedCard.detail.profileTab.socialCircleEarnings",
      },
      // tree: [{
      //     firstPerson: {
      //         name: 'John Walker',
      //         // image: 'https://picsum.photos/300/300?random=1'
      //     },
      //     secondPerson: {
      //         name: 'Jannet Grem',
      //         // image: 'https://picsum.photos/300/300?random=2'
      //     },
      //     children: [{
      //         firstPerson: {
      //             name: 'Katia'
      //         },
      //         secondPerson: {
      //             name: 'Oleg'
      //         },
      //     }]
      // }]
      tree: [],
    };
  },
  computed: {
    message() {
      return this.GET_LOCALE == "sv" ? "message" : "eng_message";
    },
    features() {
      if (this.getLang == "") {
        return this.swedishLang ? "features" : "features";
      } else return this.getLang == "sv" ? "features" : "features";
    },
    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  mounted() {
    // this.getFeeRule()
    const {uuid} = this.$route.params;
    if (uuid) {
      this.getMemberInformation(uuid);
    }
  },
  watch: {
    "feeRuleHolder": {
      handler(value) {
        if (value === null || value === undefined) {
          this.createFamilyForm.fee_rule = ''
        }
      },
    },
  },
  methods: {
    disabledFields() {
      let hidden_object = this.hiddenResource
      try {
        hidden_object['hidden_fields']['golf_related']['memebrs'].map(item => {
          this.hidden_fields[item] = false
        })
        this.generalForm.map(item => {
          if (Object.keys(this.hidden_fields).includes(item.fieldValue) === true) {
            item.disabled = this.hidden_fields[item.fieldValue]
          } else {
            item.disabled = true
          }
        })

      } catch (e) {
      }
    },
    renderUserInfo() {
      if (this.user) {
        this.generalForm.map((el) => {
          if (Object.keys(this.user).includes(el.fieldValue)) {
            el.value = this.user[el.fieldValue];
          }
        });
      }
    },
    computeSelect(item, key) {
      const indexValue = this.generalForm.findIndex(
          (el) => el.fieldValue === key
      );
      if (indexValue !== -1) {
        this.generalForm[indexValue].value = item.value;
      }
    },
    removeMember(index) {
      this.selectedMembers.splice(index, 1);
    },
    computeCreateSelect(item, key) {
      this.createFamilyForm[key] = item.uuid;
    },
    getMemberInformation(uuid) {
      this.$useJwt
          .getMembers({URL: `/${uuid}`, method: "get"})
          .then((res) => {
            this.singleMember = res.data.data;
            this.generalForm.map((el) => {
              if (Object.keys(res.data.data).includes(el.fieldValue)) {
                el.value = res.data.data[el.fieldValue];
              }
            });
            this.createFamilyTree(this.singleMember.family);
          })
          .catch((err) => {
            this.loading = false;
          });
    },

    getFeeRule() {
      this.$useJwt.getFeeRules({URL: "", method: "get"}).then((res) => {
        this.feeRuleResult = res.data.data.results;
      });
    },

    getMembers() {
      this.$useJwt.getMembers({URL: "", method: "get"}).then((res) => {
        this.feeRuleResult = res.data.data.results;
      });
    },
    searchMembers(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteMembers(loading, search, this);
      }
    },
    setCustomer(item) {
      let exist = this.selectedMembers.filter(customer => customer.uuid === item.uuid)
      if (exist.length <= 0) {
        item.role = this.miniFormRole.role
        this.selectedMembers.push(item)
      }
    },
    searchRemoteMembers: debounce(function (loading, search, vm) {
      this.$useJwt
          .getMembers({URL: "?search=" + escape(search), method: "get"})
          .then((res) => {
            this.members = res.data.data.results;
            loading(false);
          })
          .catch((err) => {
          });
    }, 350),

    searchFees(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteFees(loading, search, this);
      }
    },

    searchRemoteFees: debounce(function (loading, search, vm) {
      this.$useJwt
          .getFeeRules({URL: "?search=" + escape(search), method: "get"})
          .then((res) => {
            this.feeRuleResult = res.data.data.results;
            loading(false);
          })
          .catch((err) => {
          });
    }, 350),

    addMiniForm() {
      if (this.miniFormRole.role === "" || this.miniFormRole.member === "") {
        return;
      } else {
        this.selectedMembers.push({...this.miniFormRole});
        Object.keys(this.miniFormRole).forEach(
            (el) => (this.miniFormRole[el] = "")
        );
      }
    },
    updateMemberInfo() {
      this.processing = true;
      const payload = {};
      this.generalForm.map((el) => {
        payload[el.fieldValue] = el.value;
      });
      let billing = {}
      this.billingForm.map(el => {
        billing[el.fieldValue] = el.value;
      })
      this.$useJwt
          .getMembers(
              {URL: `/${this.singleMember.uuid}/`, method: "put"},
              payload
          )
          .then((res) => {
            this.processing = false;
            this.popupMsg(
                this.$t("Message.Success"),
                res.data[this.message],
                "CheckIcon",
                "success"
            );
          })
          .catch((err) => {
            this.processing = false;
            if (err.response.data.error) {
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data.error,
                  "AlertTriangleIcon",
                  "danger"
              );
            } else {
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
              );
            }
          });
    },
    createFamily() {
      this.$refs.form.validate().then((res) => {
        if (res) {
          const mode = {URL: "", method: ""};
          this.creating = true;
          const payload = this.createFamilyForm;

          if (this.editFamily) {
            mode.URL = `/${this.singleMember.family.uuid}/`;
            mode.method = "put";
          } else {
            mode.URL = "";
            mode.method = "post";
          }

          if (this.selectedMembers.length > 0) {
            this.selectedMembers.map((el) => {
              if (!el.uuid) {
                payload.members.push({
                  role: el.role,
                  member_uuid: el.member.uuid,
                });
              } else {
                payload.members.push({
                  role: el.role,
                  member_uuid: el.uuid,
                });
              }
            });
          }
          let ownerExist = payload.members.filter(item => item.member_uuid === this.singleMember.uuid)
          if (ownerExist.length <= 0) {
            this.creating = false;
            this.popupMsg(
                this.$t("Message.Failed"),
                `${this.singleMember.first_name} saknas i familjen du försöker skapa. Vänligen lägg till ${this.singleMember.first_name} och hans/hennes roll i familjen och spara igen.`,
                "AlertTriangleIcon",
                "danger"
            );
            return false
          }
          // check to validate if each member in a family tree has a role
          for (let j = 0; j < this.selectedMembers.length; j++) {
            if (this.selectedMembers[j].role === '' || this.selectedMembers[j].role === null || this.selectedMembers[j].role === undefined) {
              this.creating = false;
              this.popupMsg(
                  this.$t("Message.Failed"),
                  `Du måste ange ${this.selectedMembers[j].member + " " + this.selectedMembers[j].member}s, roll i familjen.`,
                  "AlertTriangleIcon",
                  "danger"
              );
              return false
            }
          }
          if (payload.fee_rule === '') {
            delete payload.fee_rule
          }

          this.$useJwt
              .customFamily(mode, payload)
              .then((res) => {
                this.getMemberInformation(this.$route.params.uuid);
                this.creating = false;
                this.clearForm();
                this.openFamilyCreateModal = false;
                this.popupMsg(
                    this.$t("Message.Success"),
                    res.data[this.message],
                    "CheckIcon",
                    "success"
                );
              })
              .catch((err) => {
                this.creating = false;
                if (err.response.data.error) {
                  this.popupMsg(
                      this.$t("Message.Failed"),
                      err.response.data.error,
                      "AlertTriangleIcon",
                      "danger"
                  );
                } else {
                  this.popupMsg(
                      this.$t("Message.Failed"),
                      err.response.data[this.message],
                      "AlertTriangleIcon",
                      "danger"
                  );
                }
              });
        }
      });
    },
    createFormFeeRule() {
      this.$refs.formCreateFeeRule.validate().then((res) => {
        if (res) {
          this.addFeeRule = true;

          this.$useJwt
              .getFeeRules({URL: "", method: "post"}, this.createFeeRule)
              .then((res) => {
                this.getFeeRule();
                this.addFeeRule = false;
                this.createFeeRule.name = "";
                this.createFeeRule.discount = 0;
                this.openFeeRule = false;
                this.popupMsg(
                    this.$t("Message.Success"),
                    res.data[this.message],
                    "CheckIcon",
                    "success"
                );
              })
              .catch((err) => {
                this.addFeeRule = false;
                if (err.response.data.error) {
                  this.popupMsg(
                      this.$t("Message.Failed"),
                      err.response.data.error,
                      "AlertTriangleIcon",
                      "danger"
                  );
                } else {
                  this.popupMsg(
                      this.$t("Message.Failed"),
                      err.response.data[this.message],
                      "AlertTriangleIcon",
                      "danger"
                  );
                }
              });
        }
      });
    },
    openCreateModal() {
      this.createFamilyForm = {
        members: [],
        fee_rule: "",
        family_name: "",
        rating: 0,
      };
      this.selectedMembers = [];
      this.miniFormRole.member = this.singleMember
      this.editFamily = false;
      this.openFamilyCreateModal = true;
    },
    createFamilyTree(family) {
      const array = [];
      if (family.family_name) {
        const obj = {};
        obj["firstPerson"] = {
          name: family.family_name,
          image: "https://img.icons8.com/color/512/defend-family--v2.png",
        };
        obj["children"] = [];
        family.members.map((el) => {
          obj["children"].push({
            firstPerson: {
              name: el.member,
              role: el.role
            },
          });
        });
        array.push(obj);
        this.tree = array;
      }
    },
    updateFamily() {
      this.createFamilyForm = {
        members: [],
        fee_rule: '',
        family_name: this.singleMember.family.family_name,
        rating: this.singleMember.family.rating,
      };
      this.selectedMembers = this.singleMember.family.members
      if (this.singleMember.fee_rule) {
        if (this.singleMember.fee_rule.hasOwnProperty('uuid')) {
          this.feeRuleHolder = this.singleMember.fee_rule.name
          this.createFamilyForm.fee_rule = this.singleMember.fee_rule.uuid
        }
      }

      this.getFamilyInfo();
      this.editFamily = true;
      this.openFamilyCreateModal = true;
    },
    getFamilyInfo() {
      this.$useJwt
          .customFamily({
            URL: ``,
            method: "get",
          })
          .then((res) => {
            if (res.data.data.results.length > 0) {
              this.createFamilyTree(res.data.data.results[0]);
            }
          });
    },
    clearForm() {
      this.selectedMembers = [];
      this.createFamilyForm.members = [];
      this.createFamilyForm.fee_rule = "";
      this.createFamilyForm.family_name = "";
      this.createFamilyForm.rating = 0;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.custom-card {
  display: flex;
  align-items: center;
  width: 220px;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 0 6px 2px rgb(0 0 0 / 0%);
  transition: box-shadow 0.2s ease;
}
</style>
